var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',{staticClass:"header-2 mb-4"},[_vm._v(_vm._s(_vm.$t("INVOICES_FOR_CHECK")))]),(_vm.showData)?_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("NO_DATA_FOR_PREVIEW")))])]):_c('div',[_c('div',[(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-start align-items-center mb-4"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex flex-column justify-content-between"},[_c('label',[_vm._v(_vm._s(_vm.$t("TOTAL_NON_FISCAL")))])]),_c('b-link',{attrs:{"to":{name: _vm.routes.RN_INVOICE_LIST, query:{
                           invoice_type: [_vm.INVOICE_TYPE_FISCAL],
                           status: _vm.INVOICE_STATUS_CREATED,
                            }},"target":"_blank"},on:{"click":function($event){return _vm.open(1)}}},[_vm._v(_vm._s(_vm.non_fiscal)+" ")])],1)]),_c('div',[(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-start align-items-center"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex flex-column justify-content-between"},[_c('label',[_vm._v(_vm._s(_vm.$t("TOTAL_PROCESSING_INVOICES")))])]),_c('b-link',{attrs:{"to":{name: _vm.routes.RN_INVOICE_LIST, query:{
                           status: _vm.INVOICE_STATUS_PROCESSING,
                            }},"target":"_blank"},on:{"click":function($event){return _vm.open(2)}}},[_vm._v(_vm._s(_vm.processing)+" ")])],1)]),_c('div',[(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-start align-items-center"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex flex-column justify-content-between"},[_c('label',[_vm._v(_vm._s(_vm.$t("TOTAL_UNPROCESSING_INVOICES")))])]),_c('b-link',{attrs:{"to":{name: _vm.routes.RN_INVOICE_LIST, query:{
                           status: _vm.INVOICE_STATUS_CREATED,
                            }},"target":"_blank"},on:{"click":function($event){return _vm.open(3)}}},[_vm._v(_vm._s(_vm.unprocessed)+" ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }