<template v-if="licence">
    <div>
        <b-row>
            <b-col lg="12" md="12" class="col-xxl-12">
                <form-header header-class="mt-4 p-0">
                    {{ $t('LICENCE') }}: {{ licence.name }}
                    <b-badge v-if="showLicenceStatus"
                             class="ml-2"
                             :variant="licence.active ? 'primary' : 'danger'">
                        {{ licence.active ? $t('ACTIVE') : $t('INACTIVE') }}
                    </b-badge>
                </form-header>
            </b-col>
        </b-row>
        <b-row v-for="(variant, variantIndex) in licence.variants" class="mt-2"
               :key="'licence_' + licence.id + '_variant_' + variant.id">
            <b-col lg="12" md="12" class="col-xxl-12 mt-2"
                   v-for="(content, contentIndex) in variant.content"
                   :key="'licence_' + licence.id + '_variant_' + variant.id + '_content_' + contentIndex">
                <div class="border_not_last d-flex mr-3">
                    <span>{{ licence.variants.length === 1 ? $t('VARIANT') : $t('VARIANT') + ' ' + (variantIndex + 1) }}: {{ variant.name }}</span>
                    <span>{{ content.name }}</span>
                    <template v-if="licence.level === 'property'">
                        <span v-if="content.value !== null">{{ content.value }}</span>
                        <span v-if="content.value === null" class="text-lowercase">
                            {{ content.required ? $t('REQUIRED') : $t('OPTIONAL') }}
                        </span>
                    </template>
                    <!-- there should always be a single unit -->
                    <template v-else-if="content.value !== null" v-for="(unitLicenceValue, unitEid) in content.value">
                        <span v-if="unitLicenceValue.value !== null"
                              :key="'licence_' + licence.id + '_variant_' + variant.id + '_content_' + contentIndex + '_unit_' + unitEid + '_value'">
                            {{ unitLicenceValue }}
                        </span>
                        <span v-if="unitLicenceValue === null"
                              class="text-lowercase"
                              :key="'licence_' + licence.id + '_variant_' + variant.id + '_content_' + contentIndex + '_unit_' + unitEid + '_requirement'">
                            {{ content.required ? $t('REQUIRED') : $t('OPTIONAL') }}
                        </span>
                    </template>
                    <template v-else>
                        <span class="text-lowercase">
                            {{ content.required ? $t('REQUIRED') : $t('OPTIONAL') }}
                        </span>
                    </template>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import FormHeader from "@/components/app/form/FormHeader";

export default {
    name: "DistributionActivationWizardBookingLicenceForm",
    components: {FormHeader},
    props: {
        licence: {
            type: Object,
            default() {
                return null
            },
        },
        showLicenceStatus: {
            type: Boolean,
            default: true,
        },
    },
}
</script>

<style scoped>
.border_not_last span:not(:last-child) {
    border-right: 1px solid lightgray;
    margin-right: .6rem;
    padding-right: .5rem;
}
</style>
