<template>
    <b-card class="property-wrapper res-no-border">
        <div slot="header">
            <h2>{{ $t('PAYMENT_GATEWAYS') }}</h2>
        </div>
        <payment-gateway-list></payment-gateway-list>
    </b-card>
</template>

<script>
import PaymentGatewayList from "@/components/payment_gateway/PaymentGatewayList";

export default {
    name: "Index",
    components: {PaymentGatewayList},
}
</script>

<style scoped>
.res-no-border > div {
    border: none !important;
}
</style>
