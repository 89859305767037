<template>
    <div>
        <b-row v-if="dataLoaded">
            <template v-if="checkPermission(INTEGRATIONS_PG_PAYMENT_GATEWAY)">
                <b-col class="col-xxl-6">
                    <payment-gateway-item :key="'active_' + index" v-for="(paymentGateway, index) in paymentGatewayList"
                                          v-if="paymentGateway.setup_id !== null"
                                          :payment-gateway="paymentGateway"
                                          :company="Number(company)"
                                          :confirmation-state="confirmationState"
                                          @activateSetup="activateSetup"
                                          @deactivateSetup="deactivateSetup"
                                          @editSetup="editSetup">
                    </payment-gateway-item>
                </b-col>
                <b-col class="col-xxl-6">
                    <payment-gateway-item :key="'inactive_' + index"
                                          v-for="(paymentGateway, index) in paymentGatewayList"
                                          v-if="paymentGateway.setup_id === null"
                                          :payment-gateway="paymentGateway"
                                          :company="Number(company)"
                                          :confirmation-state="confirmationState"
                                          @activateSetup="activateSetup"
                                          @deactivateSetup="deactivateSetup"
                                          @editSetup="editSetup">
                    </payment-gateway-item>
                </b-col>
            </template>
        </b-row>
        <app-aside v-model="appAsideState" :widths="['col-lg-3 col-sm-5', '40%']">
            <template slot="header">
                <div class="header-caption">{{ setupId === null ? $t('ACTIVATE') : $t('EDIT') }}</div>
                <h2 v-if="paymentGateway !== null" class="property-wrapper-padding">{{ paymentGateway.name }}</h2>
            </template>
            <payment-gateway-edit-form :payment-gateway="paymentGateway"
                                       :company="Number(company)"
                                       @setupSaved="setupSaved">
            </payment-gateway-edit-form>
        </app-aside>
    </div>
</template>

<script>
import {deactivatePaymentGatewaySetup, getPaymentGatewayList} from "@/services/payment_gateway";
import PaymentGatewayItem from "@/components/payment_gateway/PaymentGatewayItem";
import AppAside from "@/components/app/form/AppAside";
import PaymentGatewayEditForm from "@/components/payment_gateway/PaymentGatewayEditForm";
import {notifySuccess} from "@/shared/plugins/toastr";
import {INTEGRATIONS_PG_PAYMENT_GATEWAY} from "@/shared/component_permission";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";

export default {
    name: "PaymentGatewayList",
    components: {PaymentGatewayEditForm, PaymentGatewayItem, AppAside},
    mixins: [getErrorMessage],
    data() {
        return {
            paymentGatewayList: [],
            dataLoaded: false,
            appAsideState: false,
            paymentGateway: null,
            setupId: null,
            confirmationState: false,
            INTEGRATIONS_PG_PAYMENT_GATEWAY,
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCompany']
        },
    },
    methods: {
        getPaymentGateways() {
            getPaymentGatewayList({company: this.company}).then(response => {
                this.paymentGatewayList = response.data
            }, () => {
                this.paymentGatewayList = []
            }).finally(() => {
                this.dataLoaded = true
            })
        },
        activateSetup(paymentGateway) {
            this.confirmationState = true
            this.paymentGateway = paymentGateway
            this.appAsideState = true
            this.$nextTick(() => {
                this.confirmationState = false
            })
        },
        deactivateSetup(setupId) {
            this.confirmationState = true
            deactivatePaymentGatewaySetup(setupId).then(() => {
                notifySuccess()
                this.getPaymentGateways()
            }, error => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.confirmationState = false
            })
        },
        editSetup(paymentGateway) {
            this.paymentGateway = paymentGateway
            this.appAsideState = true
        },
        setupSaved() {
            this.getPaymentGateways()
            this.appAsideState = false
        },
    },
    created() {
        this.getPaymentGateways()
    },
}
</script>

<style scoped>

</style>
