<template>
    <div>
        <b-form @submit.prevent="save">
            <b-row class="mb-2">
                <b-col lg="12" class="mb-4">
                    <app-input-control :error-object="validationErrorObject.title">
                        <template v-slot:input="data">
                            <label>{{ $t('NAME') }}</label>
                            <b-input :state="data.state" v-model="title"></b-input>
                        </template>
                    </app-input-control>

                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6">
                    <app-button-submit :loading="loading"></app-button-submit>
                </b-col>
                <b-col lg="6" v-if="filter_id !== null">
                    <app-button-delete @click="deleteDialogState=true" class="pull-right"></app-button-delete>
                </b-col>
            </b-row>

            <app-confirmation-dialog
                :show="deleteDialogState"
                @confirm="deleteFilter"
                @cancel="deleteDialogState=false"
                :delete_title="true"
            ></app-confirmation-dialog>

        </b-form>
        <template v-if="filter_id !== null">

            <b-form class="mt-5" @submit.prevent="saveUnits" >

                <b-row >
                    <b-col>
                        <table class="table b-table">
                            <thead>
                            <th width="70%">{{$t('UNIT')}}</th>
                            <th>{{ $t('COEFFICIENT') }}</th>
                            <th></th>
                            </thead>
                            <tbody>
                            <tr :key="unit.id+'_'+index" v-for="(unit, index) in unit_list">
                                <td>

                                    <small class="text-muted d-block">
                                        <b-link v-if="checkPermission(R_PROPERTY_BASIC) && unit.property.id"
                                                :to="{name: routeNames.RN_PROPERTY_BASIC, params: {id: unit.property.id}}"
                                                target="_blank">
                                            {{unit.property.name}}
                                        </b-link>
                                        <template v-else>
                                            {{unit.property.name}}
                                        </template>
                                    </small>
                                    <b-link v-if="checkPermission(R_PROPERTY_UNIT_BASIC)"
                                            :to="{name: routeNames.RM_PROPERTY_UNIT_BASIC, params: {id: unit.property.id, unit_id: unit.id}}"
                                            target="_blank">
                                        {{unit.name}}
                                    </b-link>
                                    <template v-else>
                                        {{unit.name}}
                                    </template>
                                </td>
                                <td>
                                    <app-number-input
                                        :value="unit.coefficient"
                                        @input="setCoefficient($event, index)"
                                        :step="0.1"
                                        :min="0"
                                        :max="2"
                                        allow-decimal
                                        :decimals="2">
                                    </app-number-input>
                                </td>
                                <td><app-button @click="removeRow(index)" variant="link" button_type="delete" class="pull-right" :show_text="false"></app-button></td>
                            </tr>
                            <tr >
                                <td><app-select v-model="new_unit"  mode="search" text-field="name" value-field="id" :search="searchUnitList" ></app-select></td>
                                <td><app-button :disabled="new_unit === null" class="pull-right"  button_type="new" @click="addNewRow" >{{$t('ADD_ITEM')}}</app-button></td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <app-button-submit :disabled="saveUnitListDisabled" :loading="loader_unit"></app-button-submit>
                    </b-col>
                </b-row>
            </b-form>
        </template>

    </div>

</template>

<script>
import AppSubmit from "@/components/app/AppSubmit";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButtonDelete from "@/components/app/AppButton/AppButtonDelete";
import {
    createRmFilterUnit,
    deleteRmFilterUnit,
    getRmFilterUnitById, rmFilterSetUnits, searchRmUnits,
    updateRmFilterUnit
} from "@/services/revenue_manager";
import {toast} from "@/shared/plugins/toastr";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import AppButton from "@/components/app/AppButton/AppButton";
import FormHeader from "@/components/app/form/FormHeader";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import {getUnitList} from "@/services/unit";
import AppInputControl from "@/components/app/AppInputControl";
import {FILTER_TITLE} from "@/shared/error_codes";
import {R_PROPERTY_UNIT_BASIC,R_PROPERTY_BASIC} from "@/shared/route_permission";
import routeNames from "@/router/routeNames";
import AppNumberInput from "@/components/app/form/AppNumberInput";

export default {
    name: "RevenueManagerFilterForm",
    props: {
        id: {
            type: Number,
            default:null
        },

    },
    data(){
        return {
            title:null,
            filter_id:null,
            deleteDialogState:false,
            loading:false,
            unit_list:[],
            new_unit:null,
            loader_unit:false,
            validationErrorObject:{
                title:FILTER_TITLE
            },
            R_PROPERTY_UNIT_BASIC,
            R_PROPERTY_BASIC,
            routeNames
        }
    },
    mixins: [getErrorMessage],
    components: {
        AppNumberInput,
        AppInputControl,
        AppSelect, FormHeader, AppButton, AppConfirmationDialog, AppButtonDelete, AppButtonSubmit, AppSubmit},
    methods: {
        addNewRow(){
            if(this.new_unit && this.new_unit.hasOwnProperty('id')){
                this.unit_list.push({
                    id: this.new_unit.id,
                    name: this.new_unit.title,
                    property: {
                        id: this.new_unit.property.id,
                        name: this.new_unit.property.name,
                    },
                    coefficient: 1,
                })
            }
            this.new_unit = null
        },
        removeRow(index){
            this.unit_list.splice(index,1)
        },
        searchUnitList(value) {
            let req = {
                    name:value,
                    page: 1,
                    perPage: 50,
                    company: this.company.id
                }

            return  searchRmUnits(req).then(response => {
                        const unitWithProperty = response.data.items.map(el=>{
                            return {
                                id: el.id,
                                title :el.title,
                                property: el.property,
                                name: el.title + ' (' + el.property.name + ')',
                                coefficient: el.coefficient,
                            }
                        })
                       return {data: unitWithProperty}
                })

        },
        saveUnits(){

            this.loader_unit = true
            const units = this.unit_list.map(el=>{
                return {
                    unit: el.id,
                    coefficient: el.coefficient,
                }
            })
            rmFilterSetUnits(this.filter_id,{units}).then(response =>{
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
                this.$emit('close')
            }, error=>{
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
            }).finally(()=>{
                this.loader_unit = false
            })
        },
        save() {
            this.loading = true
            if(this.id === null){
                createRmFilterUnit({title:this.title,user:this.user.id}).then(response=>{
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.filter_id = response.data
                }, error=>{

                }).finally(()=>{
                    this.loading = false
                })
            } else {
                updateRmFilterUnit(this.id,{title:this.title}).then(response=>{
                    toast({
                        'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                        'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                        'type': 'success',
                        'timeout': 3000
                    })
                    this.$emit('close')
                }, error=>{

                }).finally(()=>{
                    this.loading = false
                })
            }
        },
        deleteFilter() {
            deleteRmFilterUnit(this.id).then(response=>{
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_SUCCESS.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_SUCCESS.MESSAGE'),
                    'type': 'success',
                    'timeout': 3000
                })
                this.deleteDialogState = false
                this.$emit('close')
            }, error=>{
                toast({
                    'title': this.$t('NOTIFICATIONS.SAVE_ERROR.TITLE'),
                    'message': this.$t('NOTIFICATIONS.SAVE_ERROR.MESSAGE', {value: this.getMessages(error)}),
                    'type': 'error',
                    'timeout': 6000
                })
                this.deleteDialogState = false
            }).finally(()=>{

                this.laoding = false
            })
        },
        fetchById(id){
            getRmFilterUnitById(id).then(response=>{
                this.unit_list = response.data.unit_list
                this.title = response.data.title
                this.filter_id = response.data.id
            })
        },
        setCoefficient(coefficient, index) {
            this.$set(this.unit_list[index], 'coefficient', coefficient)
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        user() {
            return this.$store.getters['user/getUser']
        },
        saveUnitListDisabled() {
            if (this.unit_list.length === 0) {
                return true
            }
            let validCoefficient = true
            this.unit_list.forEach((unit) => {
                if (!unit.hasOwnProperty('coefficient') || !unit.coefficient || (Number(unit.coefficient)) < 0.1 || (Number(unit.coefficient)) > 2) {
                    validCoefficient = false
                }
            })
            return !validCoefficient
        },
    },
    watch:{
        id:{
            handler(value){
                if(value){
                    this.fetchById(value)
                }
            },immediate:true
        }
    }
}
</script>

<style scoped>

</style>
