<template>
    <div>
        <div class="dashboard-wrapper">
            <b-card>
                <b-row>
                    <b-col md="12" lg="9" class="d-flex align-items-start flex-column">
                        <div class="header-2 mb-2">
                            <b-img style="width: 54px;" :src="'data:image/png;base64,' + paymentGateway.icon"
                                   alt="logo"></b-img>
                            <span class="ml-3">{{ paymentGateway.name }}</span>
                        </div>
                        <div class="d-flex no-wrap mt-4" v-if="paymentGateway.setup_id">
                            <app-button v-if="checkPermission(INTEGRATIONS_PG_SETUP_EDIT)"
                                        variant="link" class="action_button"
                                        @click="editSetup">
                                {{ $t('EDIT') }}
                            </app-button>
                        </div>
                    </b-col>
                    <b-col md="12" lg="3"
                           class="bl-lg-show pl-lg-3 pt-3  pt-lg-0 d-flex flex-column justify-content-end">
                        <app-button v-if="!paymentGateway.setup_id"
                                    :v_show="checkPermission(INTEGRATIONS_PG_SETUP_ACTIVATE)"
                                    :loading="loadingActivate"
                                    @click="activateSetup">{{ $t('ACTIVATE') }}
                        </app-button>
                        <app-button v-else
                                    :v_show="checkPermission(INTEGRATIONS_PG_SETUP_DEACTIVATE)"
                                    class="mt-2"
                                    @click="deactivate">
                            {{ $t('DEACTIVATE') }}
                        </app-button>
                    </b-col>
                </b-row>
            </b-card>
        </div>
        <app-confirmation-dialog :show="showDeactivateConfirmationDialog"
                                 :title="$t('DEACTIVATION_CONFIRMATION')"
                                 @confirm="deactivateSetup"
                                 @cancel="showDeactivateConfirmationDialog = false">
            {{ $t('PAYMENT_GATEWAY_DEACTIVATE_SETUP_CONFIRMATION_MSG', {payment_provider: paymentGateway.name}) }}
        </app-confirmation-dialog>
    </div>
</template>

<script>
import AppButton from "@/components/app/AppButton/AppButton";
import AppConfirmationDialog from "@/components/app/form/AppConfirmationDialog";
import {
    INTEGRATIONS_PG_SETUP_EDIT,
    INTEGRATIONS_PG_SETUP_ACTIVATE,
    INTEGRATIONS_PG_SETUP_DEACTIVATE,
} from "@/shared/component_permission";

export default {
    name: "PaymentGatewayItem",
    components: {AppConfirmationDialog, AppButton},
    props: {
        paymentGateway: {
            type: Object
        },
        company: {
            type: Number
        },
        confirmationState: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loadingActivate: false,
            showActivateConfirmationDialog: false,
            showDeactivateConfirmationDialog: false,
            INTEGRATIONS_PG_SETUP_EDIT,
            INTEGRATIONS_PG_SETUP_ACTIVATE,
            INTEGRATIONS_PG_SETUP_DEACTIVATE,
        }
    },
    methods: {
        deactivate() {
            this.showDeactivateConfirmationDialog = true
        },
        activateSetup() {
            this.loadingActivate = true
            this.$emit('activateSetup', this.paymentGateway)
        },
        deactivateSetup() {
            this.$emit('deactivateSetup', Number(this.paymentGateway.setup_id))
        },
        editSetup() {
            this.$emit('editSetup', this.paymentGateway)
        },
    },
    watch: {
        confirmationState: {
            handler(value) {
                if (!value) {
                    this.loadingActivate = false
                    this.showDeactivateConfirmationDialog = false
                }
            },
        },
    },
}
</script>

<style scoped>

</style>
