var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mini_calendar--cell position-relative mini_calendar--availability-cell",class:_vm.classList,style:({ 'grid-column': _vm.columnPosition + '/ span ' + _vm.day.colspan })},[(_vm.startOfWeekOrMonth)?_c('div',{staticClass:"justify-content-start pull-left",class:{
                'mini_calendar--status':_vm.day.hasReservationOnDayBefore,
                'mini_calendar--background_primary ':_vm.day.hasReservationOnDayBefore,
                'mini_calendar--status-end_of_res':_vm.day.hasReservationOnDayBefore}}):_vm._e(),(_vm.day.reservation)?_c('div',{staticClass:" mini_calendar--status mini_calendar--background_primary cell",class:{
                'mini_calendar--status-start-res-before':_vm.day.reservation.check_in_date === _vm.availabilityItemKey && _vm.day.isCheckOutThisWeek,
                'mini_calendar--status-start-res_before-no-check-out':_vm.day.reservation.check_in_date === _vm.availabilityItemKey && !_vm.day.isCheckOutThisWeek,
                'mini_calendar--status-end':_vm.day.isCheckOutThisWeek,
                'mini_calendar--reservation':_vm.day.isCheckOutThisWeek && !_vm.day.isCheckInThisWeek},style:(("width: " + _vm.calculateWidth + ";")),attrs:{"id":("popover-reservation-" + (_vm.day.reservation.id))}},[(_vm.day.isCheckInThisWeek)?_c('div',{staticClass:"justify-content-start main_calendar_test-guest--icon"},[_c('b-img',{attrs:{"fluid":"","src":_vm.day.reservation.channel_icon}})],1):_vm._e(),(!_vm.day.isCheckInThisWeek)?_c('div',{staticClass:"justify-content-end align-items-center pl-3",style:(("width: " + (_vm.day.colspan*2.5) + "rem;"))},[_vm._v(" "+_vm._s(_vm.day.date.format('D'))+" ")]):_vm._e(),_vm._l((_vm.calcColspan),function(span1){return _c('div',{staticClass:"justify-content-end align-items-center pl-3",style:(("width: " + (_vm.day.colspan*2.5) + "rem;"))},[_vm._v(" "+_vm._s(_vm.day.date.add(1,'day').format('D'))+" ")])})],2):_vm._e(),(!_vm.day.reservation)?[(_vm.isContigent && _vm.isAvailable && !_vm.day.reservation)?_c('div',{staticClass:"justify-content-start pb-4 ml-1 mini_calendar_numeric-indicator"},[_c('span',[_vm._v(_vm._s(_vm.contigentLabel))])]):_vm._e(),(!_vm.isContigent && _vm.isAvailable && !_vm.day.reservation && !_vm.day.availabilityObject.open_for_sale)?_c('div',{staticClass:"justify-content-start pb-4 cell "},[_c('i',{staticClass:"fa fa-circle ml-1 mr-1 mini_calendar_indicator-open",staticStyle:{"font-size":"0.3rem"}})]):_vm._e(),(!_vm.isAvailable && !_vm.day.reservation && _vm.day.availabilityObject.open_for_sale)?_c('i',{staticClass:"justify-content-end pb-4 cell pull-right "},[_c('i',{staticClass:"fa fa-circle ml-1 mr-1 mini_calendar_indicator-open pull-right",staticStyle:{"font-size":"0.3rem"}})]):_vm._e(),(!_vm.isContigent && _vm.isAvailable && !_vm.day.reservation)?_c('div',{staticClass:"justify-content-end ml-1 cell pull-right "},[_c('i',{staticClass:"fa fa-circle ml-1 mr-1 mini_calendar_indicator-padding pull-right",staticStyle:{"font-size":"0.3rem"}})]):_vm._e(),(_vm.startOfWeekOrMonth)?_c('div',{class:{
                'mini_calendar--status':_vm.day.hasReservationOnDayBefore,
                'mini_calendar--background_primary ':_vm.day.hasReservationOnDayBefore,
                'mini_calendar--status-end_of_res':_vm.day.hasReservationOnDayBefore}}):_vm._e(),_c('div',{staticClass:"justify-content-center mini_calendar--status availability-cell"},[_c('div',[_vm._v(_vm._s(_vm.day.date.format('D')))])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }