var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pr-3 pl-3 pb-4",staticStyle:{"border-radius":"5px","border":"1px solid #EAEAEA"}},[_c('b-row',[_c('b-col',[_c('form-header',[_vm._v(_vm._s(_vm.$t('TEMPLATE_CONTENT')))])],1)],1),_c('b-row',[_vm._l((_vm.templateConfigWithoutLists),function(ref){
var property = ref[0];
var propertyConfig = ref[1];
return [_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","xl":_vm.getXlColWidth(propertyConfig)}},[(_vm.labelIsSeparate(propertyConfig.type))?_c('label',[_vm._v(" "+_vm._s(propertyConfig.label)+" ")]):_vm._e(),_c(_vm.getComponent(propertyConfig),{tag:"component",attrs:{"checked":_vm.formData[property],"value":_vm.getValue(_vm.formData[property], propertyConfig),"unchecked-value":false,"label":propertyConfig.label,"image":_vm.formData[property] && _vm.formData[property].id,"preview":_vm.formData[property] && _vm.formData[property].url,"sidebar-description":propertyConfig.rtf ? '' : null,"context-label":_vm.$t('PAGE_CONTENT'),"translations":_vm.formData[property],"languages":_vm.website.supported_languages,"component":propertyConfig.rtf ? "rich-text" : "textarea","options":propertyConfig.allowed_values,"text-field":"label","search-empty-item":!propertyConfig.required,"image-search":_vm.getWebsiteGalleryImages,"rich-text-options":propertyConfig.rtf ? _vm.getRichTextOptions(propertyConfig.rtf) : null,"rows":5,"allow-decimal":!!propertyConfig.allow_decimal,"min":propertyConfig.min || 0,"switch":""},on:{"input":function (value) { return _vm.setValue(_vm.formData, property, propertyConfig, value); },"update":function (value) { return _vm.setValue(_vm.formData, property, propertyConfig, value); },"updateTranslations":function($event){_vm.formData[property] = $event}}})],1)]})],2),_vm._l((_vm.templateConfigLists),function(ref){
var property = ref[0];
var listItemConfig = ref[1];
return [_c('hr'),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-between align-items-center"},[_c('form-header',[_vm._v(_vm._s(listItemConfig.label))]),_c('div',[_c('app-button',{attrs:{"button_type":"new"},on:{"click":function($event){return _vm.addItem(_vm.formData[property], listItemConfig.properties)}}},[_vm._v(" "+_vm._s(_vm.$t('NEW_ITEM'))+" ")])],1)],1)],1),_c('draggable',{attrs:{"handle":".handle"},model:{value:(_vm.formData[property]),callback:function ($$v) {_vm.$set(_vm.formData, property, $$v)},expression:"formData[property]"}},_vm._l((_vm.formData[property]),function(item,index){return _c('div',{key:index,staticClass:"mb-4"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-button',{staticClass:"text-left",attrs:{"block":"","variant":"outline-primary"},on:{"click":function($event){item.visible=!item.visible}}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_c('i',{staticClass:"fa fa-bars mr-2 handle",staticStyle:{"cursor":"move"}}),_c('b-badge',{staticClass:"mr-1 number-badge",attrs:{"pill":"","variant":"primary"}},[_vm._v(" "+_vm._s(index + 1)+" ")]),_vm._v(" "+_vm._s(_vm.$t('ITEM'))+" ")],1),_c('i',{class:("fa fa-caret-" + (item.visible ? 'up' : 'down'))})])]),_c('app-button',{staticClass:"ml-2",attrs:{"button_type":"delete","show_text":false,"variant":"link"},on:{"click":function($event){return _vm.removeItem(_vm.formData[property], index)}}})],1),_c('b-collapse',{attrs:{"id":("item-" + index)},model:{value:(item.visible),callback:function ($$v) {_vm.$set(item, "visible", $$v)},expression:"item.visible"}},[_c('b-row',[_vm._l((Object.entries(listItemConfig.properties)),function(ref){
var itemProperty = ref[0];
var itemPropertyValues = ref[1];
return [_c('b-col',{staticClass:"mt-4",attrs:{"cols":"12","xl":_vm.getXlColWidth(itemPropertyValues)}},[(_vm.labelIsSeparate(itemPropertyValues.type))?_c('label',[_vm._v(" "+_vm._s(itemPropertyValues.label)+" ")]):_vm._e(),_c(_vm.getComponent(itemPropertyValues),{tag:"component",attrs:{"checked":item[itemProperty],"value":_vm.getValue(item[itemProperty], itemPropertyValues),"unchecked-value":false,"label":itemPropertyValues.label,"image":item[itemProperty] && item[itemProperty].id,"preview":item[itemProperty] && item[itemProperty].url,"context-label":_vm.$t('PAGE_CONTENT'),"translations":item[itemProperty],"languages":_vm.website.supported_languages,"component":itemPropertyValues.rtf ? "rich-text" : "textarea","options":itemPropertyValues.allowed_values,"text-field":"label","search-empty-item":!itemPropertyValues.required,"image-search":_vm.getWebsiteGalleryImages,"rows":5,"allow-decimal":!!itemPropertyValues.allow_decimal,"min":itemPropertyValues.min || 0,"switch":""},on:{"input":function (value) { return _vm.setValue(item, itemProperty, itemPropertyValues, value); },"update":function (value) { return _vm.setValue(item, itemProperty, itemPropertyValues, value); },"updateTranslations":function($event){item[itemProperty] = $event}}})],1)]})],2)],1)],1)}),0)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }