<template>
    <div>
        <app-table-v2 :fields="fields" :filter="filter"
                      :provider="getData"
                      :limit-list="[10, 20, 50]"
                      :defaultLimit="20"
                      @busyToggled="toggleBusy">
            <template v-slot:edit="data">
                <app-button v-if="distribution && distribution.id" @click="openAside(data)"
                            variant="link"
                            button_type="edit"
                            :show_text="false">
                </app-button>
                <app-button v-if="setup_parameter"
                            @click="openParameter(data)"
                            variant="link"
                            button_type="edit"
                            :show_text="false">
                </app-button>
            </template>
        </app-table-v2>
        <app-aside v-model="showAside">
            <template slot="header">
                <app-object-header
                    :name="asideHeader"
                    :label="$t('RATE_PLAN')">
                </app-object-header>
            </template>
            <template v-if="channelList.length" slot="header_action_button">
                <div class="aside__header--action_button col-4 mr-3">
                    <label class="label">{{ $t('CHANNEL') }}</label>
                    <app-select mode="select" :options="channelList" v-model="selectedChannel" value-field="id"
                                text-field="label" :search-empty-item="false"></app-select>
                </div>
            </template>
            <distribution-reservation-sidebar :distribution_context="distribution.id"
                                              @saved="showAside=false"
                                              :rate_plan_id="rate_plan_id"></distribution-reservation-sidebar>
        </app-aside>
        <app-aside v-model="showSetupParameters">
            <template slot="header">
                <app-object-header
                    :name="asideHeader"
                    :label="$t('RATE_PLAN')">
                </app-object-header>
            </template>
            <reservation-setup-parameter :parameter_id="setup_parameter" @saved="showSetupParameters=false"
                                          :context_company="company.id" :rate_plan="rate_plan_id"></reservation-setup-parameter>
        </app-aside>
    </div>
</template>

<script>


import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {getRatePlanList} from "@/services/pricing";
import {EventBus, GE_TABLE_SEARCH, GE_TABLE_REFRESH_SEARCH} from "@/shared/EventBus";
import {getDistributionChannelList} from "@/services/distribution";
import {DISTRIBUTION_MANAGERS} from "@/shared/constants";

export default {
    name: "RatePlanTable",
    components: {
        "ReservationSetupParameter": () => import("@/components/reservation/setup/ReservationSetupParameter"),
        "AppSelect": () => import("@/components/app/AppSelect/AppSelect"),
        "DistributionReservationSidebar": () => import("@/components/channel_manager/distribution/DistributionReservationSidebar"),
        "AppObjectHeader": () => import("@/components/app/AppObjectHeader"),
        "AppTableV2": () => import("@/components/app/AppTableV2"),
        "AppButton": () => import("@/components/app/AppButton/AppButton"),
        "AppAside": () => import("@/components/app/form/AppAside")
    },
    mixins: [getErrorMessage],
    props: {
        unit_id: {
            type: Number,
            default: null
        },
        filter: {
            type: Object
        },
        setup_parameter: {
            type: Number,
            default: null
        },
        distribution: {
            type: Object,
            default: () => {
                return {
                    id: null,
                    name: null
                }
            }
        }
    },
    data() {
        return {
            fields: [
                {key: 'id', label: this.$t('ID')},
                {key: 'name', label: this.$t('NAME'), sortable: true},
                {key: 'rate_plan_type.name', label: this.$t('TYPE')},
                {key: 'linked_value', label: this.$t('LINKED_VALUE')},
                {key: 'distribution.name', label: this.$t('DISTRIBUTION')},
                {key: 'cancellation_policy.name', label: this.$t('CANCELLATION_POLICY')},
                {key: 'unit.name', label: this.$t('UNIT')},
                {key: 'edit', label: '', class: ' pl-2 text-right'}
            ],
            showAside: false,
            rate_plan_id: null,
            asideHeader: null,
            selectedChannel: null,
            channelList: [],
            showSetupParameters: false
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods: {
        getData(filter) {
            let req = {
                ...this.filter,
                ...{
                    page: filter.currentPage,
                    perPage: filter.perPage,
                    order_by: filter.sortBy,
                    order_by_direction: filter.sortDirection,
                }
            }

            return getRatePlanList(req)
        },
        updateTable() {
            EventBus.$emit(GE_TABLE_REFRESH_SEARCH)
        },
        toggleBusy($event) {
            this.$emit('busyToggled', $event)
        },
        openAside(data) {
            this.asideHeader = data.item.name
            this.rate_plan_id = data.item.id
            this.showAside = true
        },
        openParameter(data) {
            this.asideHeader = data.item.name
            this.rate_plan_id = data.item.id
            this.showSetupParameters = true
        },
        getChannelList() {
            getDistributionChannelList({distribution_id: this.distribution.id}).then(response => {
                this.channelList = response.data
                this.selectedChannel = this.channelList[Object.keys(this.channelList)[0]].id
            })
        },
    },
    watch: {
        filter: {
            handler() {
                EventBus.$emit(GE_TABLE_SEARCH)
            }
        }
    },
    created() {
        if (DISTRIBUTION_MANAGERS.includes(this.distribution.id)) {
            this.getChannelList()
        }
    }
}
</script>

<style scoped>

</style>
